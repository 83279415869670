<template>
  <tbody>
    <tr @click.prevent="collapse = !collapse" class="clickable tr-header">
      <th colspan="3">{{ child.lastAndFirstName() }}</th>
      <th class="number">{{ realDuration }}</th>
      <th class="number">{{ paidDuration }}</th>
      <th class="number">{{ absencesCount }}</th>
      <th :class="{'highlight': freqRate > 107}" class="number percentage">{{ freqRateText }}</th>
    </tr>
    <tr v-for="ins of displayedInscriptions" :key="ins.id">
      <td>{{ ins.date | dateToString }}</td>
      <td>{{ displayHourMin(ins.arrivedAt) }}</td>
      <td>{{ displayHourMin(ins.leftAt) }}</td>
      <td class="number">{{ ins.getReportDuration() }}</td>
      <td class="number">{{ ins.getPaidDuration() }}</td>
      <td>{{ ins.getAbsence() }}</td>
      <td :class="{'highlight': !isValidFreqRate(ins)}" class="number percentage">
        {{ freqRateValue(ins) }}
      </td>
    </tr>
  </tbody>
</template>

<script>
// @ is an alias to /src
import { BackendMixin } from '@/mixins/backend'
import { displayHourMin } from '@/utils/time'
import { dateToString } from '@/filters/texts'
import { sum } from '@/utils/math'

export default {
  name: 'nursery-child-frequentation',
  mixins: [BackendMixin],
  components: {
  },
  props: {
    child: Object,
    inscriptions: [],
    showDetail: Boolean,
  },
  data() {
    return {
      realDuration: 0,
      inscriptionDuration: 0,
      paidDuration: 0,
      absencesCount: 0,
      collapse: true,
    }
  },
  watch: {
    inscriptions: function() {
      this.init()
    },
  },
  computed: {
    freqRate() {
      return this.calcFreqRate(this.realDuration, this.paidDuration)
    },
    freqRateText() {
      return '' + this.freqRate + '%'
    },
    displayedInscriptions() {
      if (this.showDetail || !this.collapse) {
        return this.inscriptions
      } else {
        return []
      }
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    displayHourMin,
    init() {
      this.absencesCount = this.inscriptions.filter(elt => elt.absence).length
      this.realDuration = Math.round(100 * sum(this.inscriptions.map(elt => elt.getReportDuration()))) / 100
      this.paidDuration = Math.round(100 * sum(this.inscriptions.map(elt => elt.getPaidDuration()))) / 100
      this.inscriptionDuration = Math.round(
        100 * sum(this.inscriptions.map(elt => elt.getInscriptionDuration()))
      ) / 100
    },
    freqRateValue(ins) {
      if (!ins.absence) {
        const paid = ins.getPaidDuration()
        if (paid) {
          const value = this.calcFreqRate(ins.getReportDuration(), ins.getPaidDuration())
          return '' + value + '%'
        }
      }
      return ''
    },
    isValidFreqRate(ins) {
      if (!ins.absence) {
        const value = this.calcFreqRate(ins.getReportDuration(), ins.getPaidDuration())
        return value <= 107
      }
      return true
    },
    calcFreqRate(real, paid) {
      if (real) {
        return Math.round(10000 * paid / real) / 100
      } else {
        return 0
      }
    },
  },
}
</script>

<style lang="less" scoped>
tr.tr-header th {
  background: #ccc;
}
.highlight {
  background: #ffd948 !important;
}
</style>
